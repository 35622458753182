import React from 'react';
import {minEmploymentDuration, steps} from "../constants";
import axios from "axios";
import {apiSecret, apiUrl} from "../apisettings";

export default class EmploymentStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            occupations: null
        }
    }

    componentDidMount() {
        axios.get(apiUrl + 'occupations' ,  {
            headers: {
                'X-Fuse-Apikey': apiSecret
            }
        })
        .then((response) => {
            this.setState({occupations: response.data});
        });
    }

    render() {
        if (this.props.currentStep !== steps.EMPLOYMENT_STATUS || !this.state.occupations) {
            return null
        }
        
        return(
            <React.Fragment>

                <h2 className="subheading">What is your employment status?</h2>
                {this.props.duration > 0 && this.props.editingAddress === null &&
                    <React.Fragment>
                        <div className="subtext" style={{
                            border: '1px solid #D8DEE5',
                            borderRadius: '5px',
                            margin: '20px',
                            padding: '20px'
                        }}>
                            <p className="subtext"><strong>Employment History</strong></p>
                            {this.props.history_array.map((v, i) => {
                                return <p key={i}><small>{this.props.addressString(v)} <span style={{color: '#006EF9', cursor: "pointer"}} onClick={() => this.props.editOldEmployment(i)}>Edit</span></small></p>
                            })}
                        </div>
                    </React.Fragment>
                }
                
                {this.props.duration > 0 && this.props.duration < minEmploymentDuration && this.props.editingAddress === null &&
                    <p className="subtext" style={{color: 'red'}}>We require at least three years' employment history, please add previous employment details.</p>
                }

                <div className="formContentWrapper">
                    <div className="buttonWrapper">
                        {Object.keys(this.props.employmentOptions).map((item, index) =>
                        <div className="optionButtonWrapper" key={index}>
                            <input
                            type="radio"
                            name="status"
                            value={item}
                            id={item}
                            className="optionButtonInput"
                            checked={this.props.status === item}
                            onChange={this.props.handleChange}
                            />
                            <label className="optionButton" htmlFor={item}>
                                {this.props.employmentOptions[item]}
                            </label>
                        </div>
                        )}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
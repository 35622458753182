import React from 'react';
import axios from "axios";
import {apiSecret, apiUrl} from "../apisettings";
import {steps} from "../constants";
import Select from 'react-select';

export default class EmploymentDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            occupations: null
        }
    }

    componentDidMount() {
        axios.get(apiUrl + 'occupations' ,  {
            headers: {
                'X-Fuse-Apikey': apiSecret
            }
        })
        .then((response) => {
            let occupations = response.data;
            let jobArray = [];
            occupations.map((v) => {
                return jobArray.push({value: v.id, label: v.name});
            });
            this.setState({occupations: jobArray}, () => {
            });
        });
    }




    render() {
        if (this.props.currentStep !== steps.EMPLOYMENT_DETAILS || !this.state.occupations) {
            return null
        }

        return(
            <React.Fragment>
                <h2 className="subheading">Can you tell us about your employer?</h2>
                <div className={"textAlign"} style={{maxWidth: '330px', marginLeft: 'auto', marginRight: 'auto', color: '#077056', backgroundColor: '#EDF8F5', border: '1px solid #47B59A', borderRadius: '10px', padding: '15px', fontSize: '13px'}}>
                <span style={{fontWeight: 700}}>Your employer will not be contacted.</span><br/>
                    Lenders use this information as part of their decision making process.
                </div>
                <div className="formContentWrapper">
                    <div className="form-group full-input">
                        <label htmlFor="job_title">Job Title</label>
                        <Select 
                                className="form-control"
                                id="job_title"
                                name="job_title"
                                placeholder="Search Job Title"
                                onChange={(selected) => this.props.handleChange({
                                    target: {
                                        name: "job_title",
                                        value:selected.value.toString()
                                    }
                                })}
                                options={this.state.occupations}
                        />
                        <div className="errors">
                            {this.props.errors.job_title.length !== 0 &&
                            <span className="error">{this.props.errors.job_title}</span>}
                        </div>
                    </div>
                    <div className="form-group full-input">
                        <label htmlFor="company_name">Employer</label>
                        <input
                            className="form-control"
                            id="company_name"
                            name="company_name"
                            type="text"
                            placeholder="Employer Name"
                            value={this.props.employment_history.company_name}
                            onChange={this.props.handleChange}
                        />
                        <div className="errors">
                            {this.props.errors.employer.length !== 0 &&
                            <span className="error">{this.props.errors.employer}</span>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
import React from 'react';
import {steps} from "../constants";

export default class Contact extends React.Component {

    render() {
        if (this.props.currentStep !== steps.CONTACTANDSUBMIT) {
            return null
        }

        let marketingCheckmark = (this.props.marketingAgreed === true) ? <img src="/check.svg" alt="" /> : "";

        return (
            <React.Fragment>
                <h2 className="subheading">One last thing...</h2>
                <div className="formContentWrapper">
                    <div className="form-group full-input">
                        <label htmlFor="email">Email Address</label>
                        <input
                            className={"form-control " + (this.props.errors.email.length !== 0 ? 'form-error' : '')}
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email Address"
                            value={this.props.email}
                            onChange={this.props.handleChange}
                        />
                        <div className="errors">
                            {this.props.errors.email.length !== 0 &&
                            <span className="error">{this.props.errors.email}</span>}
                        </div>
                    </div>
                    <div className="form-group full-input" style={{marginBottom: '30px'}}>
                        <label htmlFor="phone">Phone Number</label>
                        <input
                            className={"form-control" + (this.props.errors.phone.length !== 0 ? 'form-error' : '')}
                            id="phone"
                            name="phone"
                            type="number"
                            placeholder="Mobile Number"
                            value={this.props.phone}
                            onChange={this.props.handleChange}
                        />
                        <div className="errors">
                            {this.props.errors.phone.length !== 0 &&
                            <span className="error">{this.props.errors.phone}</span>}
                        </div>
                    </div>
                    <p style={{marginBottom: '10px', padding: '0 10px'}}>In addition to contacting you regarding your enquiry, you also agree to staying informed by ourselves and our partners on other relevant offers and deals related to finance and vehicles.</p>
                    <div className={"form-group full-input checkbox " + (this.props.marketingAgreed === true ? 'checked' : '')}>
                        <label htmlFor="marketingAgreed">
                            <span className="checkbox-custom">{marketingCheckmark}</span>
                            <input
                                className="form-control"
                                id="marketingAgreed"
                                name="marketingAgreed"
                                type="checkbox"
                                defaultChecked={this.props.marketingAgreed}
                                onClick={this.props.handleChange}
                            />
                            I agree
                        </label>
                    </div>
                    <div className={"form-group full-input checkbox blueBox"}>
                        <div>
                            By submitting this application, you are consenting to a soft credit search which will not show on your credit profile. If you choose to enter a finance agreement, or seek an approval from certain lenders, a hard search will be required. By submitting this application, you agree to our
                            {this.props.source === "Caruno" ? (
                                <a href="https://www.caruno.co.uk/terms-of-business/" target="_blank" rel="noopener noreferrer">Terms of Business</a>
                            ) : (
                                <a href="https://www.hippomotorgroup.com/terms-of-business/" target="_blank" rel="noopener noreferrer">Terms of Business</a>
                            )}
                            .
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
import React from 'react';
import {steps} from "../constants";
import moment from "moment";
export default class DateOfBirth extends React.Component {

    constructor(props) {
        super(props);
        const date = moment(this.props.date_of_birth, 'DD/MM/YYYY');

        this.state = {
            day: date.isValid() ? date.format('DD') : '',
            month: date.isValid() ? date.format('MM') : '',
            years: date.isValid() ? date.format('YYYY') : ''
        };
    }


    handleChange = (e) => {
        let {name, value} = e.target;
        let length = 2;
        if(name === 'year') {
            length = 4;
        }
        value = value.replace(/[^0-9]/g, "");
        value = value.slice(0, length);
        
        if(name === 'month') {
            if(this.state.day && this.state.day.length < 2) {
                this.setState({day: `0` + this.state.day});
            }
        }

        if(name === 'year') {
            if(this.state.month < 10 && this.state.month.length < 2) {
                this.setState({month: `0` + this.state.month});
            }
        }

        if(value.length === length) {
            if(name === 'day') {
                const m = document.querySelector(`#inputmonth`);
                if(m !== null) {
                    m.focus();
                }
            }
            if(name === 'month') {
                const y = document.querySelector(`#inputyear`);
                if(y !== null) {
                    y.focus();
                }
            }
        }

        this.setState({[name]: value}, () => {
            if(Boolean(this.state.day) && Boolean(this.state.year) && Boolean(this.state.month)) {
                const date = this.state.day + "/" + this.state.month + "/" + this.state.year;
                this.props.handleChange({
                    target: {
                        name: 'date_of_birth',
                        value: date
                    }
                });
            }
        });
    };

    render() {
        if (this.props.currentStep !== steps.DATE_OF_BIRTH) {
            return null
        }

        return(
            <React.Fragment>
                <h2 className="subheading">What is your date of birth?</h2>
                <div className={"textAlign"} style={{maxWidth: '330px', marginLeft: 'auto', marginRight: 'auto', color: '#077056', backgroundColor: '#EDF8F5', border: '1px solid #47B59A', borderRadius: '10px', padding: '15px', fontSize: '13px'}}>
                We need to take some personal details to perform a soft credit check on your credit file. A soft credit check has <span style={{fontWeight: 700}}>no impact</span> on your credit score.
                </div>
                <div className="formContentWrapper">
                  <div className="dobWrapper">
                    <div style={{color: '#979797', marginBottom: '12px'}}>e.g 2nd of May 1986 becomes 02 05 1986</div>
                    <div className="form-group dob-day">
                        <input
                            className={"form-control " + (this.props.errors.date_of_birth.length !==0? 'form-error' : '')}
                            id="inputday"
                            name="day"
                            type="text"
                            placeholder="dd"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            value={this.state.day}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group dob-month">
                        <input
                            className={"form-control " + (this.props.errors.date_of_birth.length !==0 ? 'form-error' : '')}
                            id="inputmonth"
                            name="month"
                            type="text"
                            placeholder="mm"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            value={this.state.month}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="form-group dob-year">
                        <input
                            className={"form-control " + (this.props.errors.date_of_birth.length !==0 ? 'form-error' : '')}
                            id="inputyear"
                            name="year"
                            type="text"
                            placeholder="yyyy"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            value={this.state.year}
                            onChange={this.handleChange}
                        />
                    </div>
                  </div>
                </div>
                <div className="errors">
                    {this.props.errors.date_of_birth.length !== 0 &&
                    <span className="error">{this.props.errors.date_of_birth}</span>}
                </div>
            </React.Fragment>
        );
    }
}
/* eslint-disable */
export default function InitKiss(site) {

    let keys = {
        "Hippo Leasing": "70c87927ea304978cc3371dda02e2d7486b87571",
        "Hippo Motor Finance": "f519da7534fe3dab99c7ca853ca4d9af64f38c09",
        "Hippo Portal": "c525321d86d756e0c35966f33ea929455bb8962f",
        "Zero Deposit Leasing": "0b9ef4b8bc012cc5c038ca54b86e2fa077b5785f",
        "Hippo Test": "5747407409d4b58b2158b7dc6e3d5469db3af6c2" // do not use in production
    };
    if(typeof keys[site] === 'undefined') {
        return;
    }
    //Set up the tracking snippet to allow us to alias the ID.
    //***If your Kissmetrics snippet is already present in the iframe, you can delete
    //everything between this line and the next starred comment.***
        var KM_SKIP_VISITED_SITE=1;  // Prevent recording Visited Site in the iframe
        window._kmq = window._kmq || [];
        var _kmk = _kmk || keys[site]; //Be sure to enter your API key here
        function _kms(u){
            setTimeout(function(){
                var d = document, f = d.getElementsByTagName('script')[0],
                    s = d.createElement('script');
                s.type = 'text/javascript'; s.async = true; s.src = u;
                f.parentNode.insertBefore(s, f);
            }, 1);
        }
        _kms('//i.kissmetrics.io/i.js');
        _kms('//scripts.kissmetrics.io/' + _kmk + '.2.js');
    //***If snippet is present already, delete down to here***
    //This event listener aliases the anonymous ID to the main site's ID
        function listener(event){
            var eventsplit = event.data.split(":");
            console.log(eventsplit);
            if (eventsplit[0] == "KMID"){
                window._kmq.push(['alias', eventsplit[1]]);
            }

        }
    //This adds the above event listener to the iframe

    if (window.addEventListener){
        addEventListener("message", listener, false)

    } else {
        attachEvent("onmessage", listener)
    }
}

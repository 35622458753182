import React from 'react';

export default class StepProgress extends React.Component {
    render() {
        let totalSteps = this.props.totalSteps;
        let currentStep = this.props.currentStep;
        let arr = Array.apply(null, {length: totalSteps}).map(Number.call, Number);
        return(
            <div className="stepsContainer">
                {arr.map((item, index) => {
                // var isComplete;
                // if(currentStep > index+1){
                //     isComplete = <img src="/complete.svg" alt="" className="stepComplete" />;
                // } else {
                //     isComplete = index+1;
                // }
                return <div key={index} className={"step " + (currentStep > index+1 ? 'previous' : '' ) + (currentStep === index+1 ? 'current' : '')}>
                    {/* <div className="stepIcon">
                        {isComplete}
                    </div> */}
                </div> 
            })}
            </div>
        )
    }
}
import React from 'react';
import {steps} from "../constants";

export default class Submit extends React.Component {

  render() {
    if (this.props.currentStep !== steps.SUBMIT) {
      return null
    }

    var creditCheckmark = (this.props.creditChecked === true) ? <img src="/check.svg" alt="" /> : "";
    var marketingCheckmark = (this.props.marketingAgreed === true) ? <img src="/check.svg" alt="" /> : "";

    return(
      <React.Fragment>
        <h2 className="subheading">Submit Application</h2>
        <p className="subtext">Credit Search</p>
        <div className="formContentWrapper">
          <div className={"form-group full-input checkbox " + (this.props.creditChecked === true? 'checked' : '')}>
            <label htmlFor="consentCredit">
              <span className="checkbox-custom">{creditCheckmark}</span>
              <input
                className="form-control"
                id="consentCredit"
                name="consentCredit"
                type="checkbox"
                defaultChecked={this.props.consentCredit}
                onClick={this.props.handleChange}
                />
                By submitting this application, you are consenting to a soft credit search which will not show on your credit profile.
              </label>
          </div>
          <div className={"form-group full-input checkbox " + (this.props.marketingAgreed === true ? 'checked' : '')}>
            <label htmlFor="marketingAgreed">
              <span className="checkbox-custom">{marketingCheckmark}</span>
              <input
                className="form-control"
                id="marketingAgreed"
                name="marketingAgreed"
                type="checkbox"
                defaultChecked={this.props.marketingAgreed}
                onClick={this.props.handleChange}
                />
                In addition to contacting you regarding your enquiry, you also agree to staying informed by ourselves and our partners on other relevant offers and deals related to finance and vehicles.
              </label>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

import React from 'react';
import {steps} from "../constants";

export default class DrivingLicence extends React.Component {
    render() {
        if (this.props.currentStep !== steps.DRIVING_LICENSE) {
            return null
        }
        return(
        <React.Fragment>
            <div style={{marginTop: '15px', maxWidth: '470px', marginLeft: 'auto', marginRight: 'auto'}}>
                <div style={{marginBottom: '8px', display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                    <img src="/ribbon.svg" alt="" style={{marginRight: '8px'}} /> <span>No impact on your credit score from filling out this form<sup style={{marginBottom: '10px'}}>*</sup></span>
                </div>
                <div style={{marginBottom: '8px', display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                    <img src="/ribbon.svg" alt="" style={{marginRight: '8px'}} /> Valid for 28 days with no pressure to buy
                </div>
                <div style={{marginBottom: '50px', display: 'flex', alignItems: 'center', textAlign: 'left'}}>
                    <img src="/ribbon.svg" alt="" style={{marginRight: '8px'}} /> We are a credit broker, not a lender
                </div>
                <div style={{maxWidth: '270px', marginLeft: 'auto', marginRight: 'auto', display: 'flex', whiteSpace: 'nowrap', color: '#077056', backgroundColor: '#EDF8F5', border: '1px solid #47B59A', borderRadius: '50px', padding: '8px 15px', fontSize: '13px'}}>
                    Questions take less than <span style={{fontWeight: 700, paddingLeft: '4px'}}>2 minutes</span> <img src={"/clock.svg"} style={{marginLeft: '8px', display: 'inline-block'}} alt="" />
                </div>
            </div>
            <h2 className="subheading">Which driving licence do you have?</h2>
            <div className="formContentWrapper">
                <div className="buttonWrapper">
                    {Object.keys(this.props.drivingOptions).map((item, index) =>
                    <div className="optionButtonWrapper" key={item}>
                        <input
                            type="radio"
                            name="driving_license"
                            value={item}
                            id={item}
                            className="optionButtonInput"
                            checked={this.props.driving_license === item}
                            onChange={this.props.handleChange}
                        />
                        <label className="optionButton" htmlFor={item}>
                            {this.props.drivingOptions[item]}
                        </label>
                    </div>
                    )}
                </div>
            </div>
            <div className="errors">
                {this.props.errors.driving_license.length !== 0 &&
                <span className="error">{this.props.errors.driving_license}</span>}
            </div>
        </React.Fragment>
        );
    }
}
import uuid from 'react-uuid';

const employmentStatuses = {
    fulltimepermanent: 'Employed',
    selfemployed: 'Self Employed',
    unemployed: 'Not working',
    housewife: 'Homemaker',
    unabletowork: 'Unable to Work',
    retired: 'Retired',
    student: 'Student',
    parttimepermanent: 'Part-Time Employed',
};
const noEmploymentStatuses = [
    'unemployed',
    'retired',
    'student',
    'housewife',
    'unabletowork',
];
const maritalOptions = {
    married: 'Married',
    single: 'Single',
    commonlaw: "Cohabiting",
    divorced: 'Divorced',
    widowed: 'Widowed',
    civilpartners: 'Civil Partnership',
    separated: "Separated"
};
const drivingLicenseTypes = {
    fulluk: 'Full UK Driving Licence',
    provisionaluk: 'Provisional',
    international: 'International',
    eu: 'European',
    none: 'No Licence',
};
const residenceTypes = {
    homeowner: 'Home Owner',
    private: 'Private Tenant',
    council: 'Council Tenant',
    livingwithparents: 'Living with Parents'
};
const passportTypes = {
    'fulluk': 'UK Passport',
    'ukcitizen': 'UK Citizen No Passport',
    'eu': 'EU Passport',
    'other': 'Other',
};
const titles = {
    mr: 'Mr',
    mrs: 'Mrs',
    miss: 'Miss',
    ms: 'Ms',
    dr: 'Dr',
    other: 'Other'
};
const steps = {
    DRIVING_LICENSE: 1,
    MARITAL_STATUS: 2,
    DATE_OF_BIRTH: 3,
    ADDRESS_STATUS: 4,
    ADDRESS: 5,
    ADDRESS_DURATION: 6,
    EMPLOYMENT_STATUS: 7,
    EMPLOYMENT_DETAILS: 8,
    EMPLOYMENT_DURATION: 9,
    INCOME: 10,
    NAME: 11,
    CONTACTANDSUBMIT: 12,
};

const employmentSteps = [steps.EMPLOYMENT_STATUS, steps.EMPLOYMENT_DETAILS, steps.EMPLOYMENT_DURATION, steps.INCOME];
const addressSteps = [steps.ADDRESS, steps.ADDRESS_STATUS, steps.ADDRESS_DURATION];

const defaultState = {
    currentStep: 1,
    totalSteps: 12,
    titleOptions: titles,
    driving_license: 'fulluk',
    drivingOptions: drivingLicenseTypes,
    marital_status: 'single',
    maritalOptions: maritalOptions,
    passport: 'fulluk',
    passportOptions: passportTypes,
    date_of_birth: '',
    employmentOptions: employmentStatuses,
    noEmploymentStatuses: noEmploymentStatuses,
    employment_history_array: [],
    employmentTotalDuration: 0,
    employment_history: {
        status: 'fulltimepermanent',
        job_title: '',
        postcode: '',
        building: '',
        selectedAddress: '',
        company_name: '',
        county: '',
        street: '',
        town: '',
        net_income: '',
        years: '',
        months: '',
        uuid: uuid(),
    },
    address_history_array: [],
    addressTotalDuration: 0,
    address_history: {
        status: 'homeowner',
        postcode: '',
        building: '',
        selectedAddress: '',
        line1: '',
        line2: '',
        town: '',
        county: '',
        years: '',
        months: '',
        uuid: uuid()
    },
    residentialOptions: residenceTypes,
    title: '',
    forename: '',
    surname: '',
    phone: '',
    email: '',
    consentCredit: true,
    creditChecked: true,
    marketingAgreed: false,
    errors: {
        line1: '',
        driving_license: '',
        date_of_birth: '',
        employer: '',
        job_title: '',
        company_name: '',
        street: '',
        town: '',
        years: '',
        months: '',
        net_income: '',
        forename: '',
        surname: '',
        phone: '',
        email: '',
        county: '',
        title: ''
    }
};

const minEmploymentDuration = 36;
const minAddressDuration = 36;

export {
    employmentStatuses,
    noEmploymentStatuses,
    maritalOptions,
    drivingLicenseTypes,
    residenceTypes,
    passportTypes,
    defaultState,
    steps,
    titles,
    employmentSteps,
    addressSteps,
    minAddressDuration,
    minEmploymentDuration
}

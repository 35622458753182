import moment from "moment";
import {steps} from "./constants";

const requiredFields = {};
requiredFields[steps.DATE_OF_BIRTH] = ['date_of_birth'];
requiredFields[steps.EMPLOYMENT_DETAILS] = ['company_name', 'job_title'];
requiredFields[steps.EMPLOYMENT_ADDRESS] = ['postcode', 'street', 'county', 'postcode'];
requiredFields[steps.INCOME] = ['net_income'];
requiredFields[steps.ADDRESS] = ['line1', 'town', 'county', 'postcode'];
requiredFields[steps.NAME] = ['title', 'forename', 'surname'];
requiredFields[steps.CONTACT] = ['phone', 'email'];


const selectValidation = value => {
    if(value.length < 1) {
        return 'Please select an option.';
    }
    return '';
};
const simpleValidation = value => {
    if(value.length < 1) {
        return 'Please fill out this field.';
    }
    return '';
};
const minimumOfTwo = value => {
    if(value.length < 2) {
        return 'Please fill out at least two characters in this field.';
    }
    return '';
};
const numeric = value => {
    if(!RegExp(/^\d*\.?\d+$/g).test(value)) {
        return 'Value must be numeric.';
    }
    return '';
};
const integer = value => {
    if(typeof(value) === 'undefined' || value === null || value.length === 0) {
        value = "0";
    }
    if(!RegExp(/^\d+$/g).test(value)) {
        return 'Value must be a whole number.';
    }
    return '';
};
const postcodeValidation = value => {
    const regex = RegExp(/([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/);
    if(!regex.test(value)) {
        return 'Please enter a valid postcode.';
    }
    return '';
};
const phoneValidation = value =>  {
    let phone = value;
    phone = phone.replace(" ", "");
    phone = phone.replace("+44", "0");
    var matches = phone.match(/0[0-9]{9,10}/);
    if (matches && matches[0] === phone) {
        return '';
    }
    return 'Please enter a valid UK phone number.';
};
const emailValidation = value => {
    /*eslint no-control-regex: "off"*/
    const regex = RegExp(/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])/g);
    if(!regex.test(value)) {
        return 'Please enter a valid email address.';
    }
    return '';
};
const validation = {
    employer: simpleValidation,
    job_title: simpleValidation,
    forename: minimumOfTwo,
    surname: minimumOfTwo,
    driving_license: value => {
        let error = '';
        if(value === 'none') {
            error = 'Sorry, our lenders are unable to offer leasing or finance without any form of driving licence. However, we can process joint applications if the other applicant holds a valid licence. If you live with a partner/parent/guardian who would be interested in a joint application then please call one of our specialist account managers on 01254 919000 to discuss further and they will be happy to help.';
        }
        return error;
    },
    date_of_birth: value => {
        let error = '';
        const regex = RegExp(/^\s*(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20)\d{2})\s*$/g);
        if(!regex.test(value)) {
            error = 'Format: dd/mm/yyyy';
        } else {
            const date = moment(value, 'DD/MM/YYYY');
            if(!date.isValid()) {
                error = 'Please enter a valid date.';
            } else {
                const now = moment();
                const diff = now.diff(date, 'years');
                if(diff < 18) {
                    error = 'You must be 18 or older to apply.';
                }
            }
        }
        return error;
    },
    street: simpleValidation,
    town: simpleValidation,
    years: value => {
        let error = integer(value);
        if(!error.length) {
            const testVal = parseInt(value);
            if(testVal < 0 || testVal > 99) {
                error = "Years must be between 0 and 99.";
            }
        }
        return error;
    },
    months: value => {
        let error = integer(value);
        if(!error.length) {
            const testVal = parseInt(value);
            if(testVal < 0 || testVal > 11) {
                error = "Months must be between 0 and 11.";
            }
        }
        return error;
    },
    net_income: value => {
        let error = numeric(value);
        if(!error.length && parseFloat(value) <= 99) {
            error = "Please enter three or more digits.";
        }
        return error;
    },
    phone: phoneValidation,
    email: emailValidation,
    postcode: postcodeValidation,
    title: selectValidation
};

export {
    validation,
    requiredFields
};

import React from 'react';
import {steps} from "../constants";

export default class AddressDuration extends React.Component {

    render() {
        if (this.props.currentStep !== steps.ADDRESS_DURATION) {
            return null
        }

        return (
            <React.Fragment>
                <h2 className="subheading">How long have you lived here?</h2>
                <React.Fragment>
                    <p onClick={this.props.editAddress} style={{display: 'flex', justifyContent: 'space-between', padding: '0 20px'}}>
                        <strong>Current Address</strong> <span style={{color: '#006EF9'}}>Edit</span>
                    </p>
                    <div
                        style={{border: "1px solid #9FB6F5", backgroundColor: '#F4F7FE', borderRadius: "5px", margin: "20px", padding: "20px", textAlign: 'left'}}
                    >
                        <p style={{color: '#656A7B', paddingBottom: '0', whiteSpace: 'pre-line'}}>
                            {this.props.addressString(this.props.address_history)}
                        </p>
                    </div>
                </React.Fragment>
                <div className="formContentWrapper">
                    <div className="form-group half-input years">
                        <div className="inputWrapper">
                            <input
                                className={"form-control " + (this.props.errors.years.length !== 0 ? 'form-error' : '')}
                                id="years"
                                name="years"
                                type="text"
                                placeholder="0"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                value={this.props.address_history.years}
                                onChange={this.props.handleChange}
                            />
                        </div>
                        <div className="errors">
                            {this.props.errors.years.length !== 0 &&
                            <span className="error">{this.props.errors.years}</span>}
                        </div>
                    </div>
                    <div className="form-group half-input months">
                        <div className="inputWrapper">
                            <input
                                className={"form-control " + (this.props.errors.months.length !== 0 ? 'form-error' : '')}
                                id="months"
                                name="months"
                                type="text"
                                placeholder="0"
                                pattern="[0-9]*"
                                inputmode="numeric"
                                value={this.props.address_history.months}
                                onChange={this.props.handleChange}
                            />
                        </div>
                        <div className="errors">
                            {this.props.errors.months.length !== 0 &&
                            <span className="error">{this.props.errors.months}</span>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
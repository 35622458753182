import StepProgress from './steps/stepProgress.js';
import Address from './steps/address';
import AddressDuration from "./steps/address_duration";
import AddressStatus from "./steps/address_status";
import Contact from "./steps/contact";
import DateOfBirth from "./steps/date_of_birth";
import DrivingLicence from "./steps/driving_licence";
import EmploymentAddress from "./steps/employment_address";
import EmploymentDetails from "./steps/employment_details";
import EmploymentStatus from "./steps/employment_status";
import EmploymentDuration from "./steps/employment_duration";
import Income from "./steps/income";
import MaritalStatus from "./steps/marital_status";
import Name from "./steps/name";
import Passport from "./steps/passport";
import Submit from "./steps/submit";
const ApplyNow = {
    StepProgress,
    Address,
    AddressDuration,
    AddressStatus,
    Contact,
    DateOfBirth,
    DrivingLicence,
    EmploymentAddress,
    EmploymentDuration,
    EmploymentDetails,
    EmploymentStatus,
    Income,
    MaritalStatus,
    Name,
    Passport,
    Submit
};
export default ApplyNow;
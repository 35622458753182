import React from "react";
import qs from "qs";
import ApplyNow from "./imports";
import axios from "axios";
import _ from "lodash";
import {
	defaultState,
	steps,
	employmentSteps,
	addressSteps,
	noEmploymentStatuses,
	minAddressDuration,
	minEmploymentDuration,
} from "./constants";
import { validation, requiredFields } from "./validation";
import { apiSecret, apiUrl } from "./apisettings";
import moment from "moment";
import uuid from "react-uuid";
import InitKiss from "./kiss";
import KissPush from "./kisstrack";
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-NBZRS6ZD'
}

TagManager.initialize(tagManagerArgs)


const validateForm = (errors) => {
	let valid = true;
	Object.values(errors).forEach(
		// if we have an error string set valid to false
		(val) => typeof val !== "undefined" && val.length > 0 && (valid = false)
	);
	return valid;
};
export default class HippoForm extends React.Component {
	

	constructor(props) {
		super(props);

		let params = document.location.search;
		let paramObj = qs.parse(params, { ignoreQueryPrefix: true });
		this.formId = paramObj.FORM ? paramObj.FORM : "Apply Now";
		this.website = paramObj.WEBSITE ? paramObj.WEBSITE : "";

		this.defaultState = {
			...defaultState,
			editingAddress: null,
			editingEmployment: null,
			formId: this.formId,
			website: (paramObj.WEBSITE) ? paramObj.WEBSITE : "",
			thankYouUrl: (paramObj.THANKYOU) ? paramObj.THANKYOU : "",
			vehicleId: (paramObj.VEHICLEID) ? paramObj.VEHICLEID : "",
			vehicle_interest: (paramObj.MAKE) ? paramObj.MAKE : "",
			vehicle_model: (paramObj.MODEL) ? paramObj.MODEL : "",
			vehicle_registration: (paramObj.REGISTRATION) ? paramObj.REGISTRATION : "",
			vehicle_derivative: (paramObj.DERIVATIVE) ? paramObj.DERIVATIVE : "",
			vehicle_cap_id: (paramObj.CAPID) ? paramObj.CAPID : "",
			vehicle_type: (paramObj.BODYTYPE) ? paramObj.BODYTYPE : "", //These two fields are not a mistake, it's just stupid.
			car_or_van: (paramObj.VEHICLETYPE) ? paramObj.VEHICLETYPE : "", //Yes, vehicleType param saves to car or van.
			Source: (paramObj.FORM) ? paramObj.FORM : "",
			source_url: (paramObj.SOURCEURL) ? paramObj.SOURCEURL : "",
			utm_source: (paramObj.UTMSOURCE) ? paramObj.UTMSOURCE : "",
			utm_medium: (paramObj.UTMMEDIUM) ? paramObj.UTMMEDIUM : "",
			utm_campaign: (paramObj.UTMCAMPAIGN) ? paramObj.UTMCAMPAIGN : "",
			utm_content: (paramObj.UTMCONTENT) ? paramObj.UTMCONTENT : "",
			utm_term: (paramObj.UTMTERM) ? paramObj.UTMTERM : "",
			sale_type: (paramObj.SALETYPE) ? paramObj.SALETYPE : "",
			yearly_mileage: (paramObj.MILEAGE) ? paramObj.MILEAGE : "",
			contract_length: (paramObj.TERM) ? paramObj.TERM : "",
			initial_payments: (paramObj.INITIALPAYMENTS !== null && typeof paramObj.INITIALPAYMENTS !== 'undefined') ? paramObj.INITIALPAYMENTS : "",
			deal_id: (paramObj.DEALID) ? paramObj.DEALID : "",
			source_category: (paramObj.SOURCECATEGORY) ? paramObj.SOURCECATEGORY : "",
			monthly_payment: (paramObj.MONTHLYPAYMENT) ? paramObj.MONTHLYPAYMENT : "",
			lease_type: (paramObj.LEASETYPE) ? paramObj.LEASETYPE : "",
			analytics_code: (paramObj.ANALYTICSCODE) ? paramObj.ANALYTICSCODE : "",
			forename: (paramObj.FORENAME) ? paramObj.FORENAME : "",
			surname: (paramObj.SURNAME) ? paramObj.SURNAME : "",
			email: (paramObj.EMAIL) ? paramObj.EMAIL : "",
			phone: (paramObj.PHONE) ? paramObj.PHONE : "",
			title: (paramObj.TITLE) ? paramObj.TITLE : "",
		};
		this.submitClicked = false;
		this.state = _.cloneDeep({
			...this.defaultState,
			//currentStep: steps.INCOME,
		});
	}

	canBeSubmitted() {
		const { phone, email, creditChecked } = this.state;
		return (
			phone.length > 0 &&
			email.length > 0 &&
			creditChecked === true &&
			validateForm(this.state.errors)
		);
	}
	getPostMessageTarget = () => {
		return  window.parent.postMessage ? window.parent : (window.parent.document.postMessage ? window.parent.document : undefined);
	};
	clearCookieOnSubmit = () => {
		let target = this.getPostMessageTarget();
		if (typeof target !== "undefined") {
			target.postMessage({
				ics_type: 'clear_cookie'
			}, "*");
		}
	};
	triggerDataSendToHippoLeasing = applicationId => {
		let { email } = this.state;
		window.parent && window.parent.postMessage({
			message: 'getApplyNowData',
			values: {
				email,
				applicationId,
			},
		}, 'https://www.hippoleasing.co.uk');
	}
	redirectOnSubmit = () => {
		let newLocation = "/thank-you/";
		switch (this.state.Source) {
			case "Hippo Leasing":
				newLocation = "https://www.hippoleasing.co.uk/thank-you-finance/";
				break;
			case "Zero Deposit Leasing":
				newLocation =
					"https://www.zerodepositcarleasing.co.uk/thank-you-finance/";
				break;
			case "Hippo Motor Finance":
				newLocation =
					"https://www.hippomotorfinance.co.uk/thank-you-finance/";
				break;
			case "Hippo Prestige":
				newLocation =
					"https://www.hippoprestige.co.uk/thank-you-finance/";
				break;
            case "Hippo Approved":
                newLocation =
                    "https://www.hippoapproved.co.uk/thank-you-finance/";
                break;
			case "Hippo Used Cars":
				newLocation =
					"https://www.hippousedcars.co.uk/thank-you-finance/";
				break;
			case "Hippo Portal":
				newLocation =
					"https://portal.hippomotorgroup.com/apply-thankyou";
				break;
			case "Time4Leasing":
				newLocation =
					"https://time4leasing.co.uk/thank-you.asp";
				break;
			case "Car2Lease":
				newLocation =
					"https://www.cars2lease.co.uk/thank-you.asp";
					break;
			case "Caruno":
				newLocation = `https://www.caruno.co.uk/apply-now/thank-you?email=${this.state.email}&forename=${this.state.forename}&surname=${this.state.surname}`;
				break;
			case "Portal":
				newLocation = `https://portal.hippomotorgroup.com/apply-now/thank-you`;
				break;
			default:

				break;
		}

		if(this.state.thankYouUrl) {
			newLocation = this.state.thankYouUrl;
		}

		let target = this.getPostMessageTarget();
		if (typeof target !== "undefined") {
			target.postMessage({
				ics_type: 'ics_redirect',
				new_location: newLocation
			}, "*");
		}
	};
	updateHeight = () => {
		const el = document.getElementById('hippoFormPageWrapper');
		let target = this.getPostMessageTarget();
		if (typeof target !== "undefined") {
			target.postMessage({
				ics_type: 'update_height',
				payload: el.clientHeight
			}, "*");
		}
	};
	scrollTop = () => {
		let target = this.getPostMessageTarget();
		if (typeof target != "undefined") {
			target.postMessage({
				ics_type: 'scroll_top',
			}, "*");
		}
	};

	componentDidUpdate(prevProps, prevState, snapshot) {
		window.requestAnimationFrame(this.updateHeight);
	}

	componentDidMount() {
		InitKiss(this.state.Source);
		window.requestAnimationFrame(this.updateHeight);
		window.requestAnimationFrame(() => {
			KissPush(['record', 'Apply now Iframe loaded']);
		});
		TagManager.dataLayer({
			dataLayer: {
				event: "hippo_form_start",
			},
		});
	}

	getBuildingFromAddressResult = (result) => {
		let building = result.building_number;
		if(Boolean(building) && Boolean(result.building_name)) {
			building = building + ", " + result.building_name;
		} else if(!Boolean(building)) {
			building = result.building_name;
		}

		if(Boolean(result.sub_building_name)) {
			building = result.sub_building_name + ", " + building;
		}
		return building;
	};

	selectEmploymentAddress = (selectedAddressId = null) => {
		if (selectedAddressId === null) {
			selectedAddressId = this.state.employment_history.selectedAddress;
		}
		axios
			.get(apiUrl + "postcode/retrieve/" + selectedAddressId, {
				headers: {
					"X-Fuse-Apikey": apiSecret,
				},
			})
			.then((response) => {
				const result = response.data.result;

				let street = result.street_name;
				if(Boolean(result.street_prefix)) {
					street = result.street_prefix + " " + street;
				}
				if(Boolean(result.street_suffix)) {
					street = street + " " + result.street_suffix;
				}


				let employment_history = this.state.employment_history;
				employment_history.selectedAddress = selectedAddressId;
				employment_history.building = this.getBuildingFromAddressResult(result);
				employment_history.street = street;
				employment_history.town = result.locality;
				employment_history.county = result.province_name;
				this.setState({
					employment_history: employment_history
				});
			});
	};
	selectAddress = (selectedAddressId = null) => {
		if (selectedAddressId === null) {
			selectedAddressId = this.state.address_history.selectedAddress;
		}
		axios
			.get(apiUrl + "postcode/retrieve/" + selectedAddressId, {
				headers: {
					"X-Fuse-Apikey": apiSecret,
				},
			})
			.then((response) => {
				const result = response.data.result;

				let street = result.street_name;
				if(Boolean(result.street_prefix)) {
					street = result.street_prefix + " " + street;
				}
				if(Boolean(result.street_suffix)) {
					street = street + " " + result.street_suffix;
				}

				let address_history = this.state.address_history;
				address_history.selectedAddress = selectedAddressId;
				address_history.building = this.getBuildingFromAddressResult(result);
				address_history.line1 = street;
				address_history.line2 = result.line_2;
				address_history.town = result.locality;
				address_history.county = result.province_name;
				this.setState({ address_history: address_history });
			});
	};

	handleEmploymentChange = (event) => {
		let { name, value } = event.target;
		if (name === "months" || name === "years") {
			if (value.length === 0) {
				value = "0";
			}
		}
		let employment_history = this.state.employment_history;
		employment_history[name] = value.length > 0 ? value : "";
		this.setState({
			employment_history: employment_history,
		});
		this.handleErrors(name, value);
	};

	handleAddressChange = (event) => {
		let { name, value } = event.target;
		if (name === "months" || name === "years") {
			if (value.length === 0) {
				value = "0";
			}
		}
		let address_history = this.state.address_history;
		address_history[name] = value.length > 0 ? value : "";
		this.setState({
			address_history: address_history,
		});
		this.handleErrors(name, value);
	};

	handleChange = (event) => {
		let currentStep = this.state.currentStep;
		let { name, value } = event.target;

		if (currentStep === steps.CONTACTANDSUBMIT) {
			switch (name) {
				case "consentCredit":
					if (event.target.checked) {
						this.setState({ creditChecked: true });
					} else {
						this.setState({ creditChecked: false });
					}
					break;
				case "marketingAgreed":
					if (event.target.checked) {
						this.setState({ marketingAgreed: true });
						value = true;
					} else {
						this.setState({ marketingAgreed: false });
						value = false;
					}
					break;
				case "email" :
				case "phone" :
					this.setState({
						[name]: value,
					});
					break;
				default:
					break;
			}
		} else {
			this.setState({
				[name]: value,
			});
		}
		this.handleErrors(name, value);
	};

	handleErrors = (name, value) => {
		let errors = this.state.errors;
		const func = validation[name];
		if (typeof func === "function") {
			errors[name] = validation[name](value);
			if (this.state.Source === "Caruno") {
				errors[name] = errors[name].replaceAll("01254 919000", "0330 0808 5788")
			}
		}
		this.setState({ errors, [name]: value });
	};

	handleSubmit = (event) => {
		event.preventDefault();
		if (!this.canBeSubmitted()) {
			event.preventDefault();
			return;
		}
		if (this.state.currentStep !== steps.CONTACTANDSUBMIT) {
			return;
		}

		if (validateForm(this.state.errors)) {
			if (this.submitClicked) {
				event.preventDefault();
				return;
			}
			this.submitClicked = true;

			for (let item of this.state.address_history_array) {
				if (item.years.length === 0) {
					item.years = 0;
				}
				if (item.months.length === 0) {
					item.months = 0;
				}
			}
			for (let item of this.state.employment_history_array) {
				if (item.years.length === 0) {
					item.years = 0;
				}
				if (item.months.length === 0) {
					item.months = 0;
				}
			}
			// Valid Form
			let formData = {
				...this.state,
				address_history: this.state.address_history_array,
				employment_history: this.state.employment_history_array,
				date_of_birth: moment(
					this.state.date_of_birth,
					"DD/MM/YYYY"
				).format("YYYY-MM-DD"),
			};

			if (formData.Source === "Caruno") {
				formData.quick_quote = {
					vehicle_id: formData.vehicleId
				}
			}

			axios
				.post(apiUrl + "application", formData, {
					headers: {
						"X-Fuse-Apikey": apiSecret,
					},
				})
				.then(({ data: { applicationId } }) => {

					TagManager.dataLayer({
						dataLayer: {
							event: 'hippo_form_submit',
						},
					});

					async function hashSHA256(str) {
						var lowerCaseStr = str.toLowerCase();
						const encoder = new TextEncoder();
						const data = encoder.encode(lowerCaseStr);
						const hashBuffer = await crypto.subtle.digest('SHA-256', data);
						const hashArray = Array.from(new Uint8Array(hashBuffer));
						return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
					}
	
					hashSHA256(formData.email).then(emailHash => {
	
						let brand = "";
						switch (formData.Source) {
							case "Hippo Leasing":
								brand = "Leasing";
								break;
							case "Zero Deposit Leasing":
								brand = "Zero Deposit";
								break;
							case "Hippo Motor Finance":
								brand = "Motor Finance";
								break;
							case "Hippo Approved":
								brand = "Approved";
								break;
							case "Caruno":
								brand = "Caruno"
								break;
							case "Car2Lease":
								brand = "Car2Lease"
								break;	
							case "Time4Leasing":
								brand = "Time4Leasing"
								break;	
							case "Portal":
								brand = "Hippo Portal"
								break;	
							default:
								break;
						}
	
						global.analytics.track("Submission Created", {
							type: "Loan Application",
							submittedBy: "Customer",
							submissionId: `${applicationId}`,
							brand: brand,
						});


						function convertToE164(ukNumber) {
							if (ukNumber.startsWith('0')) {
								return '+44' + ukNumber.substring(1);
							} else {
								return 'Invalid UK number';
							}
						}

					
						global.analytics.identify(emailHash, {
							email: formData.email,
							firstName: formData.forename,
							lastName: formData.surname,
							phone: convertToE164(formData.phone),
							// birthday: dayjs(formData.date_of_birth).format('DD-MM-YYYY')
						})

						window.requestAnimationFrame(() => this.triggerDataSendToHippoLeasing(applicationId));
						window.requestAnimationFrame(this.clearCookieOnSubmit);
						window.requestAnimationFrame(this.redirectOnSubmit);
					})
				})
				.catch((result) => {
					console.error(result);
				});
		} else {
			// Invalid Form
			console.error("Invalid Form");
		}
	};

	validateFields = () => {
		let obj = this.state;
		if (employmentSteps.includes(this.state.currentStep)) {
			obj = obj.employment_history;
		}
		if (addressSteps.includes(this.state.currentStep)) {
			obj = obj.address_history;
		}

		const validation = requiredFields[this.state.currentStep];
		if (Array.isArray(validation)) {
			for (let item of validation) {
				if (obj[item].length === 0) {
					return false;
				}
			}
		}
		if (this.state.currentStep === steps.EMPLOYMENT_DURATION) {
			let empYears = parseInt(this.state.employment_history.years);
			let empMonths = parseInt(this.state.employment_history.months);
			if (isNaN(empYears)) empYears = 0;
			if (isNaN(empMonths)) empMonths = 0;

			return empYears + empMonths > 0;
		}
		if (this.state.currentStep === steps.ADDRESS_DURATION) {
			let addYears = parseInt(this.state.address_history.years);
			let addMonths = parseInt(this.state.address_history.months);
			if (isNaN(addYears)) addYears = 0;
			if (isNaN(addMonths)) addMonths = 0;
			return addYears + addMonths > 0;
		}
		return true;
	};

	nextButton() {
		let currentStep = this.state.currentStep;
		let totalSteps = this.state.totalSteps;
		if (currentStep < totalSteps) {
			let isEnabled =
				validateForm(this.state.errors) && this.validateFields();
			return (
				<button
					disabled={!isEnabled}
					className="component-button navactive next"
					type="button"
					onClick={this._next}
				>
					Continue <img src={"/arrow_forward.svg"} style={{marginLeft: '8px'}} alt="" />
				</button>
			);
		}
		return null;
	}

	previousButton() {
		let currentStep = this.state.currentStep;
		if (currentStep !== 1) {
			return (
				<button
					className="component-button navactive"
					type="button"
					onClick={this._prev}
				>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img src={"/arrow_back.svg"} style={{marginRight: '8px'}} alt="" />
                        <div>
                            <div style={{fontSize: '15px'}}>Previous</div>
                            <div style={{fontSize: '13px'}}>(you won't lose your information)</div>
                        </div>
                    </div>
				</button>
			);
		}
		return null;
	}

	submitButton() {
		let currentStep = this.state.currentStep;
		let totalSteps = this.state.totalSteps;
		let canBeSubmitted = this.canBeSubmitted();

		if (currentStep === totalSteps) {
			if (validateForm(this.state.errors)) {
				return (
					<button
						disabled={!canBeSubmitted}
						className="component-button navactive submit"
						type="submit"
					>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <div style={{fontSize: '15px'}}>Submit Application</div>
                                <div style={{fontSize: '13px'}}>No impact on your credit score</div>
                            </div>
                            <img src={"/arrow_forward.svg"} style={{marginLeft: '8px'}} alt=""  />
                        </div>
					</button>
				);
			} else {
				return (
					<button
						disabled
						className="component-button navactive submit"
						type="submit"
					>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <div>
                                <div style={{fontSize: '15px'}}>Submit Application</div>
                                <div style={{fontSize: '13px'}}>No impact on your credit score</div>
                            </div>
                            <img src={"/arrow_forward.svg"} style={{marginLeft: '8px'}} alt="" />
                        </div>
					</button>
				);
			}
		}
		return null;
	}

	// Next / Prev / Reset button onClicks

	_next = () => {
		let currentStep = this.state.currentStep;
		let completedStep = '';
		let dataLayerEvent = '';
		switch(this.state.currentStep) {
			case steps.DRIVING_LICENSE:
				completedStep = 'Completed driving licence';
				dataLayerEvent = 'driving_license';
				break;
			case steps.MARITAL_STATUS:
				completedStep = 'Completed marital status';
				dataLayerEvent = 'marital_status';
				break;
			case steps.PASSPORT:
				completedStep = 'Completed passport';
				dataLayerEvent = 'passport';
				break;
			case steps.DATE_OF_BIRTH:
				completedStep = 'Completed DOB';
				dataLayerEvent = 'dob';
				break;
			case steps.EMPLOYMENT_STATUS:
				completedStep = 'Completed employment status';
				dataLayerEvent = 'employment_status';
				break;
			case steps.EMPLOYMENT_DETAILS:
				completedStep = 'Completed employment details';
				dataLayerEvent = 'job_title';
				break;
			case steps.EMPLOYMENT_ADDRESS:
				completedStep = 'Completed employment address';
				dataLayerEvent = 'employment_address';
				break;
			case steps.EMPLOYMENT_DURATION:
				completedStep = 'Completed employment length of service';
				dataLayerEvent = 'employment_duration';
				break;
			case steps.INCOME:
				completedStep = 'Completed monthly income';
				dataLayerEvent = 'income';
				break;
			case steps.ADDRESS:
				completedStep = 'Completed home address';
				dataLayerEvent = 'residential_address';
				break;
			case steps.ADDRESS_STATUS:
				completedStep = 'Completed residential status';
				dataLayerEvent = 'residential_status';
				break;
			case steps.ADDRESS_DURATION:
				completedStep = 'Completed time at address';
				dataLayerEvent = 'residential_duration';
				break;
			case steps.NAME:
				completedStep = 'Completed name';
				dataLayerEvent = 'name';
				break;
			case steps.CONTACTANDSUBMIT:
				completedStep = 'Completed contact details and submitted application';
				dataLayerEvent = 'hippo_form_submit';
				break;
			default:
		}

		window.requestAnimationFrame(() => {
			KissPush(['record', completedStep]);
		});

		TagManager.dataLayer({
			dataLayer: {
				event: dataLayerEvent,
			},
		});

		let totalSteps = this.state.totalSteps;
		let penultimateStep = this.state.totalSteps - 1;
		let stateUpdate = {};
		if (currentStep >= penultimateStep) {
			currentStep = totalSteps;
		} else {
			currentStep++;
			//Skip directly to EMPLOYMENT_DURATION step if unemployed status.
			if (employmentSteps.includes(this.state.currentStep)) {
				if (
					currentStep !== steps.EMPLOYMENT_STATUS &&
					noEmploymentStatuses.includes(this.state.employment_history.status) &&
					this.state.currentStep < steps.EMPLOYMENT_DURATION
				) {
					currentStep = steps.EMPLOYMENT_DURATION;
				}
			}

			const lastAddressStep = addressSteps[addressSteps.length - 1];
			if (currentStep > lastAddressStep) {
				//Add address to array. If we don't have three years history, we need to go back and fill out more.
				let addressArray = this.state.address_history_array;
				if (addressArray.length > 0) {
					const lastAddress = addressArray[addressArray.length - 1];
					if (lastAddress.uuid === this.state.address_history.uuid) {
						addressArray.pop(); //overwrite with new changes
					}
				}
				if (this.state.editingAddress !== null) {
					addressArray[this.state.editingAddress] = this.state.address_history;
				} else {
					addressArray.push({ ...this.state.address_history });
				}
				stateUpdate.address_history_array = addressArray;
				stateUpdate.editingAddress = null;
				let duration = 0;
				for (let historyItem of addressArray) {
					const months = parseInt(historyItem.months);
					const years = parseInt(historyItem.years);
					if (!isNaN(months)) {
						duration += months;
					}
					if (!isNaN(years)) {
						duration += years * 12;
					}
				}
				stateUpdate.addressTotalDuration = duration;
				if (duration < minAddressDuration) {
					stateUpdate.address_history = {
						...defaultState.address_history,
					};
					stateUpdate.address_history.uuid = uuid();
					currentStep = addressSteps[0];
					this.setState({
						address_history: {},
					});
				}
			}

			const lastEmploymentStep = employmentSteps[employmentSteps.length - 1];
			if (currentStep > lastEmploymentStep) {
				//Add employment to array. If we don't have three years history, we need to go back and fill out more.
				let employmentArray = this.state.employment_history_array;
				if (employmentArray.length > 0) {
					const lastEmployment = employmentArray[employmentArray.length - 1];
					if (lastEmployment.uuid === this.state.employment_history.uuid) {
						employmentArray.pop(); //overwrite with new changes
					}
				}
				if (this.state.editingEmployment !== null) {
					employmentArray[this.state.editingEmployment] = this.state.employment_history;
				} else {
					employmentArray.push({ ...this.state.employment_history });
				}
				stateUpdate.employment_history_array = employmentArray;
				stateUpdate.editingEmployment = null;
				let duration = 0;
				for (let historyItem of employmentArray) {
					const months = parseInt(historyItem.months);
					const years = parseInt(historyItem.years);
					if (!isNaN(months)) {
						duration += months;
					}
					if (!isNaN(years)) {
						duration += years * 12;
					}
				}
				stateUpdate.employmentTotalDuration = duration;
				if (duration < minEmploymentDuration) {
					stateUpdate.employment_history = {
						...defaultState.employment_history,
					};
					stateUpdate.employment_history.uuid = uuid();
					currentStep = employmentSteps[0];
					this.setState({
						employment_history: {}
					});
				}
			}
		}
		stateUpdate.currentStep = currentStep;

		this.setState(stateUpdate, () => {
			window.scrollTo({top: 0, behavior: 'smooth'});
			window.requestAnimationFrame(this.scrollTop);
		});
	};

	_prev = () => {
		let currentStep = this.state.currentStep;
		window.requestAnimationFrame(() => {
			KissPush(['record', 'Clicked previous step']);
		});
		if (currentStep <= 1) {
			currentStep = 1;
		} else {
			currentStep--;
			//Skip back to EMPLOYMENT_STATUS if we are ignoring the employment steps.
			if (this.state.currentStep === steps.EMPLOYMENT_DURATION) {
				if (
					noEmploymentStatuses.includes(
						this.state.employment_history.status
					)
				) {
					currentStep = steps.EMPLOYMENT_STATUS;
				}
			}
		}
		this.setState({
			currentStep: currentStep,
		}, () => {
			window.scrollTo({top: 0, behavior: 'smooth'});
			window.requestAnimationFrame(this.scrollTop);
		});
	};

	_reset = () => {
		window.requestAnimationFrame(() => {
			KissPush(['record', 'Restarted apply now form']);
		});
		this.setState(
			_.cloneDeep({
				...this.defaultState,
			}, () => {
				window.scrollTo({top: 0, behavior: 'smooth'});
				window.requestAnimationFrame(this.scrollTop);
			})
		);
	};

	editAddress = () => {
		this.setState({
			currentStep: steps.ADDRESS_STATUS,
		});
	}

	editOldAddress = (addressIndex) => {
		let address = this.state.address_history_array[addressIndex];
		this.setState({
			currentStep: steps.ADDRESS_STATUS,
			address_history: address,
			editingAddress: addressIndex,
		});
	}

	editEmployment = () => {
		this.setState({
			currentStep: steps.EMPLOYMENT_STATUS,
		});
	}
	editOldEmployment = (employmentIndex) => {
		let employment = this.state.employment_history_array[employmentIndex];
		employment.duration = 0;
		this.setState({
			currentStep: steps.EMPLOYMENT_STATUS,
			employment_history: employment,
			editingAddress: employmentIndex,
		});
	}

	addressString = (address) => {

        function isNumber(n) {
            return !isNaN(parseFloat(n)) && isFinite(n);
        }

		let addr = [];

		if (address.building) {
			addr.push(address.building);
		}
		addr.push(address.line1);
		if (typeof address.line2 !== "undefined" && address.line2.length > 0) {
			addr.push(address.line2);
		}
		addr.push(address.town, address.county, address.postcode);

		let returnAddress = "";

		// if (address.years) {
		// 	returnAddress += `${address.years} Years ${!address.months ? " at " : ""}`;
		// }

		// if (address.months) {
		// 	returnAddress += `${address.months} Months at `;
		// }

		addr.forEach((addString, index) => {
			if (addString !== "" && addString !== undefined) {
				returnAddress = returnAddress + addString;
				if (index !== addr.length -1) {
                    if(isNumber(addString)) {
                        returnAddress = returnAddress + " "
                    } else {
                        returnAddress = returnAddress + "\n"
                    }
				}
			}
		})
		return returnAddress;
	};

	render() {

		global.window.analytics.ready(function() {
			console.log(global.window.analytics.user().anonymousId())
		});

		return (
			<React.Fragment>
				<div className="pageWrapper" id="hippoFormPageWrapper">
					<div className="contentWrapper">
						{/* <h1 className="mainTitle">{this.state.formId}</h1> */}
						<ApplyNow.StepProgress
							currentStep={this.state.currentStep}
							totalSteps={this.state.totalSteps}
						/>

						<form onSubmit={this.handleSubmit}>
							<ApplyNow.DrivingLicence
								currentStep={this.state.currentStep}
								handleChange={this.handleChange}
								driving_license={this.state.driving_license}
								drivingOptions={this.state.drivingOptions}
								errors={this.state.errors}
							/>
							<ApplyNow.MaritalStatus
								currentStep={this.state.currentStep}
								handleChange={this.handleChange}
								marital_status={this.state.marital_status}
								maritalOptions={this.state.maritalOptions}
							/>
							<ApplyNow.Passport
								currentStep={this.state.currentStep}
								handleChange={this.handleChange}
								passport={this.state.passport}
								passportOptions={this.state.passportOptions}
							/>
							<ApplyNow.DateOfBirth
								currentStep={this.state.currentStep}
								handleChange={this.handleChange}
								date_of_birth={this.state.date_of_birth}
								errors={this.state.errors}
							/>
							<ApplyNow.EmploymentStatus
								currentStep={this.state.currentStep}
								handleChange={this.handleEmploymentChange}
								status={this.state.employment_history.status}
								history_array={
									this.state.employment_history_array
								}
								duration={this.state.employmentTotalDuration}
								editingAddress={this.state.editingAddress}
								employmentOptions={this.state.employmentOptions}
								addressString={this.addressString}
								editOldEmployment={this.editOldEmployment}
							/>
							<ApplyNow.EmploymentDetails
								currentStep={this.state.currentStep}
								handleChange={this.handleEmploymentChange}
								employment_history={
									this.state.employment_history
								}
								errors={this.state.errors}
							/>
							<ApplyNow.EmploymentAddress
								currentStep={this.state.currentStep}
								handleChange={this.handleEmploymentChange}
								selectEmploymentAddress={
									this.selectEmploymentAddress
								}
								employment_history={
									this.state.employment_history
								}
								errors={this.state.errors}
							/>
							<ApplyNow.EmploymentDuration
								currentStep={this.state.currentStep}
								handleChange={this.handleEmploymentChange}
								employment_history={
									this.state.employment_history
								}
								errors={this.state.errors}
								editEmployment={this.editEmployment}
							/>
							<ApplyNow.Income
								currentStep={this.state.currentStep}
								handleChange={this.handleEmploymentChange}
								net_income={this.state.employment_history ? this.state.employment_history.net_income : ""}
								employment_history={
									this.state.employment_history
								}
								errors={this.state.errors}
								editEmployment={this.editEmployment}
								addressString={this.addressString}
							/>
							<ApplyNow.Address
								currentStep={this.state.currentStep}
								handleChange={this.handleAddressChange}
								selectAddress={this.selectAddress}
								address_history={this.state.address_history}
								history_array={this.state.address_history_array}
								duration={this.state.addressTotalDuration}
								errors={this.state.errors}
								addressString={this.addressString}
								editOldAddress={this.editOldAddress}
							/>
							<ApplyNow.AddressStatus
								currentStep={this.state.currentStep}
								handleChange={this.handleAddressChange}
								status={this.state.address_history.status}
								residentialOptions={
									this.state.residentialOptions
								}
							/>
							<ApplyNow.AddressDuration
								currentStep={this.state.currentStep}
								handleChange={this.handleAddressChange}
								address_history={this.state.address_history}
								errors={this.state.errors}
								editAddress={this.editAddress}
								addressString={this.addressString}
							/>
							<ApplyNow.Name
								currentStep={this.state.currentStep}
								handleChange={this.handleChange}
								title={this.state.title}
								forename={this.state.forename}
								surname={this.state.surname}
								options={this.state.titleOptions}
								errors={this.state.errors}
							/>
							<ApplyNow.Contact
								currentStep={this.state.currentStep}
								handleChange={this.handleChange}
								phone={this.state.phone}
								email={this.state.email}
								consentCredit={this.state.consentCredit}
								creditChecked={this.state.creditChecked}
								marketingAgreed={this.state.marketingAgreed}
								errors={this.state.errors}
								source={this.state.Source}
							/>
							<ApplyNow.Submit
								currentStep={this.state.currentStep}
								handleChange={this.handleChange}
								consentCredit={this.state.consentCredit}
								creditChecked={this.state.creditChecked}
								marketingAgreed={this.state.marketingAgreed}
								errors={this.state.errors}
							/>
							<div className="navButtonsWrapper">
								{this.previousButton()}
								{this.nextButton()}
								{this.submitButton()}
							</div>
                            {this.state.currentStep === 1 && (
                                <div style={{fontSize: '13px', maxWidth: '450px', margin: '40px auto 0 auto'}}>
                                    <sup style={{marginBottom: '10px'}}>*</sup>If you choose to proceed with a finance agreement, or seek a finance approval from certain funders, a hard credit check will be required.
                                </div>
                            )}
                            <div className={"privacyWrapper"}>
                                <div style={{marginBottom: '8px', color: '#979797'}}>
                                    <img src={"/padlock.svg"} style={{marginRight: '8px'}} alt="" /> Your data is safe with us
                                </div>
                                <div>
									{this.state.Source === "Caruno" ? (
										<a href={"https://www.caruno.co.uk/privacy-policy/"} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
									) : (
										<a href={"https://www.hippomotorgroup.com/privacy-policy/"} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
									)}
                                </div>
                            </div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}
}

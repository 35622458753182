import React from 'react';
import {steps, titles} from "../constants";
import Select from 'react-select';

export default class Name extends React.Component {

    handleChange = (e) => {
        let {name, value} = e.target;
        value = value.replace(/([^ \-a-zA-Z\u00C0-\u017F']+)/g, "");
        this.props.handleChange({
            target: {
                name: name,
                value: value
            }
        });
    };

    render() {
        if (this.props.currentStep !== steps.NAME) {
            return null
        }

        let titleArray = [];
        for(let [key, value] of Object.entries(titles)) {
            titleArray.push({value: key, label: value});
        };

        return(
            <React.Fragment>
                <h2 className="subheading">Your Details</h2>
                <div className="formContentWrapper">
                    <div className="form-group half-input">
                        <label htmlFor="title">Title</label>
                        <Select
                            className={"form-control " + (this.props.errors.title.length !==0? 'form-error' : '')}
                            id="title"
                            name="title"
                            placeholder="Please Select"
                            defaultvalue={this.props.title}
                            onChange={(selected) => this.props.handleChange({
                                target: {
                                    name: "title",
                                    value:selected.value.toString()
                                }
                            })}
                            options={titleArray}
                        />
                    </div>
                    <div className="errors">
                        {this.props.errors.title.length !== 0 &&
                        <span className="error">{this.props.errors.title}</span>}
                    </div>
                    <br/>
                    <div className="form-group half-input">
                        <label htmlFor="forename">First Name</label>
                        <input
                            className={"form-control " + (this.props.errors.forename.length !==0? 'form-error' : '')}
                            id="forename"
                            name="forename"
                            type="text"
                            placeholder="First Name"
                            value={this.props.forename}
                            onChange={this.handleChange}
                        />
                        <div className="errors">
                            {this.props.errors.forename.length !== 0 &&
                            <span className="error">{this.props.errors.forename}</span>}
                        </div>
                    </div>
                    <div className="form-group half-input">
                        <label htmlFor="surname">Surname</label>
                        <input
                            className={"form-control " + (this.props.errors.surname.length !==0? 'form-error' : '')}
                            id="surname"
                            name="surname"
                            type="text"
                            placeholder="Surname"
                            value={this.props.surname}
                            onChange={this.handleChange}
                        />
                        <div className="errors">
                            {this.props.errors.surname.length !== 0 &&
                            <span className="error">{this.props.errors.surname}</span>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
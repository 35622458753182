import React from 'react';
import axios from 'axios'
import {apiUrl, apiSecret} from "../apisettings";
import {steps} from "../constants";
import {validation} from "../validation";

export default class EmploymentAddress extends React.Component {

    constructor(props) {
        super(props);
        this._showAddressFields= this._showAddressFields.bind(this);
        //this._showPostcodeSelect= this._showPostcodeSelect.bind(this);
        this._resetState= this._resetState.bind(this);
        this.addressSelect = React.createRef();
        this.state = {
            manualActive: false,
            postcodeResults: [],
            postcode: null,
            postcodeChanged: false,
            selectedAddress: undefined
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.employment_history !== this.props.employment_history) {
            this.setState({
                postcodeResults: [],
            });
        }
    }

    _showAddressFields = () => {
        const currentState = this.state.manualActive;
        this.setState({ manualActive: !currentState });
    }

    _postcodeResults = () => {
        axios.get(apiUrl + 'postcode/find/' + this.props.employment_history.postcode,  {
            headers: {
                'X-Fuse-Apikey': apiSecret
            }
        })
        .then((response) => {
            this.setState({
                postcodeResults: response.data.results,
                selectedAddress: response.data.results[0].id
            });
        });
    };

    _resetState = () => {
        this.setState({ postcodeChanged: true });
    }



    render() {

        if (this.props.currentStep !== steps.EMPLOYMENT_ADDRESS) {
            return null
        }
        let error = '';
        if(Boolean(this.props.employment_history.postcode) && this.props.employment_history.postcode.length > 0) {
            error = validation.postcode(this.props.employment_history.postcode);
        }
        var isEnabled = this.state.postcodeChanged && error.length < 1 && Boolean(this.props.employment_history.postcode);
        const showUpdateAddress =
            this.state.postcodeResults.length > 0 &&
            Boolean(this.state.selectedAddress) &&
            this.state.selectedAddress !== this.props.employment_history.selectedAddress;
        return(
            <React.Fragment>
                <h2 className="subheading">What is your employer's business address?</h2>
                <div className="formContentWrapper">
                    <div className="form-group half-input">
                        <label htmlFor="postcode">Postcode Lookup</label>
                        <div className="inputWrapper">
                            <input
                                className="form-control"
                                id="postcode"
                                name="postcode"
                                type="text"
                                placeholder="Postcode"
                                value={this.props.employment_history.postcode}
                                onChange={(e) => {
                                    this.props.handleChange(e);
                                    this._resetState(e);
                                }}
                            />
                            <button
                                disabled={!isEnabled}
                                className="postcodeSearch"
                                onClick={(e) => {
                                    /*this._showPostcodeSelect(e);*/
                                    this._postcodeResults(e);
                                }}
                            >
                                <img src="/search.svg" alt="" />
                            </button>
                        </div>
                        <div className="errors">
                            {error.length > 0 &&
                            <span className="error">{error}</span>}
                        </div>
                        <div className="information">
                            <button className="info" onClick={this._showAddressFields}>Or enter manually</button>
                        </div>
                    </div>
                    <div className={"form-group full-input hidden-fields " + (this.state.postcodeResults.length > 0 ? 'active' : '')}>
                        <label htmlFor="selectedAddress">Address Results</label>
                        <select
                            ref={this.addressSelect}
                            className="form-control"
                            id="selectedAddress"
                            name="selectedAddress"
                            placeholder="Choose Correct Address"
                            value={this.state.selectedAddress}
                            onChange={(e) => {
                                this.setState({selectedAddress: e.target.value})
                            }}
                        >
                            {this.state.postcodeResults.map((v, i) => {
                                return <option key={v.id} value={v.id}>{v.labels[1]}</option>
                            })}
                        </select>
                    </div>
                    <div className={"form-group full-input hidden-fields " + (showUpdateAddress ? 'active' : '')}>
                        <button
                            className="component-button navactive next"
                            onClick={() => {
                                this.props.handleChange({target: {
                                        name: "selectedAddress",
                                        value: this.state.selectedAddress
                                    }});
                                this.props.selectEmploymentAddress();
                            }}
                        >
                            Use Address
                        </button>
                    </div>
                    <div className={"hidden-fields " + (this.state.manualActive ? 'active' : '')}>
                        <div className="form-group half-input">
                            <label htmlFor="building">Building Number\Name</label>
                            <input
                                className="form-control"
                                id="building"
                                name="building"
                                type="text"
                                placeholder="Building Name"
                                value={this.props.employment_history.building}
                                onChange={this.props.handleChange}
                            />
                        </div>
                        <div className="form-group full-input">
                            <label htmlFor="street">Street</label>
                            <input
                                className={"form-control " + (this.props.errors.street.length !==0? 'form-error' : '')}
                                id="street"
                                name="street"
                                type="text"
                                placeholder="Street"
                                value={this.props.employment_history.street}
                                onChange={this.props.handleChange}
                            />
                            <div className="errors">
                                {this.props.errors.street.length !== 0 &&
                                <span className="error">{this.props.errors.street}</span>}
                            </div>
                        </div>
                        <div className="form-group full-input">
                            <label htmlFor="street">Town</label>
                            <input
                                className={"form-control " + (this.props.errors.town.length !==0? 'form-error' : '')}
                                id="town"
                                name="town"
                                type="text"
                                placeholder="Town"
                                value={this.props.employment_history.town}
                                onChange={this.props.handleChange}
                            />
                            <div className="errors">
                                {this.props.errors.town.length !== 0 &&
                                <span className="error">{this.props.errors.town}</span>}
                            </div>
                        </div>
                        <div className="form-group full-input">
                            <label htmlFor="street">County</label>
                            <input
                                className={"form-control " + (this.props.errors.county.length !==0? 'form-error' : '')}
                                id="county"
                                name="county"
                                type="text"
                                placeholder="County"
                                value={this.props.employment_history.county}
                                onChange={this.props.handleChange}
                            />
                            <div className="errors">
                                {this.props.errors.county.length !== 0 &&
                                <span className="error">{this.props.errors.county}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
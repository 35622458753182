import React from 'react';
import {steps} from "../constants";

export default class Income extends React.Component {

  

    render() {

        const positionHeaderStatuses = [
            'unemployed',
            'housewife',
            'unabletowork',
            'retired',
            'student',
          ];


        if (this.props.currentStep !== steps.INCOME) {
            return null
        }
        let error = "";
        if(Boolean(this.props.net_income) && this.props.net_income.length > 2) {
            if (parseFloat(this.props.net_income) < 750.00) {
                error = "You have input that your monthly income is less than £750.00. Please note that some of our lenders require a minimum income of £750pm so it may reduce the available options to you.";
            }
            if (parseFloat(this.props.net_income) > 9999) {
                error = "You have input that your monthly income is £10,000.00 or more. Please amend if this is incorrect or continue with the application if correct";
            }
        }
        return(
            <React.Fragment>
                {positionHeaderStatuses.includes(this.props.employment_history.status) ? (
                    null
                ) : (
                    <React.Fragment>
                        <p onClick={this.props.editEmployment} style={{display: 'flex', justifyContent: 'space-between', padding: '0 20px'}}>
                            <strong>Previous Employment</strong> <span style={{color: '#006EF9'}}>Edit</span>
                        </p>
                        <div
                            style={{border: "1px solid #9FB6F5", backgroundColor: '#F4F7FE', borderRadius: "5px", margin: "20px", padding: "20px", textAlign: 'left'}}
                        >
                            <p style={{color: '#656A7B', paddingBottom: '0', whiteSpace: 'pre-line'}}>
                                {this.props.employment_history.company_name}
                            </p>
                        </div>
                    </React.Fragment>
                )}
                <h2 className="subheading">What is your monthly income after tax?</h2>
                <div className="formContentWrapper">
                    <div className="form-group full-input">
                        <label htmlFor="net_income">Monthly Income (after tax)</label>
                        <input
                            className="form-control"
                            id="net_income"
                            name="net_income"
                            type="text"
                            placeholder="£"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            value={this.props.net_income}
                            onChange={this.props.handleChange}
                        />
                        <div className="errors">
                            {this.props.errors.net_income?.length !== 0 &&
                            <span className="error">{this.props.errors.net_income}</span>}
                        </div>
                        <div className="errors">
                            {error?.length > 0 &&
                                <span className="error">{error}</span>}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
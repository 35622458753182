import React from 'react';
import {steps} from "../constants";

export default class AddressStatus extends React.Component {
    render() {
        if (this.props.currentStep !== steps.ADDRESS_STATUS) {
            return null
        }
        
        return(
        <React.Fragment>
        <h2 className="subheading">What is your residential status?</h2>
        <div className="formContentWrapper">
            <div className="buttonWrapper">
                {Object.keys(this.props.residentialOptions).map((item, index) =>
                <div className="optionButtonWrapper" key={item}>
                    <input
                    type="radio"
                    name="status"
                    value={item}
                    id={item}
                    className="optionButtonInput"
                    checked={this.props.status === item}
                    onChange={this.props.handleChange}
                    />
                    <label className="optionButton" htmlFor={item}>
                        {this.props.residentialOptions[item]}
                    </label>
                </div>
                )}
            </div>
        </div>
        </React.Fragment>
        );
    }
}
import React from 'react';
import {steps} from "../constants";

export default class EmploymentDuration extends React.Component {

  getHeader = () => {
    const positionHeaderStatuses = [
      'unemployed',
      'housewife',
      'unabletowork',
      'retired',
      'student',
    ];

    if (positionHeaderStatuses.includes(this.props.employment_history.status)) {
      return "How long have you been in this position?";
    } else {
      return "How long have you worked here?";
    }
  }

  render() {
    if (this.props.currentStep !== steps.EMPLOYMENT_DURATION) {
      return null
    }

    return(
      <React.Fragment>
        <h2 className="subheading">{this.getHeader()}</h2>
        <div className="formContentWrapper">
          <div className="form-group half-input years">
            <div className="inputWrapper">
              <input
                className={"form-control " + (this.props.errors.years.length !==0? 'form-error' : '')}
                id="years"
                name="years"
                type="text"
                pattern="[0-9]*"
                inputmode="numeric"
                placeholder="0"
                value={this.props.employment_history.years}
                onChange={this.props.handleChange}
              />
            </div>
            <div className="errors">
              {this.props.errors.years.length !== 0 &&
              <span className="error">{this.props.errors.years}</span>}
            </div>
          </div>
          <div className="form-group half-input months">
            <div className="inputWrapper">
              <input
                className={"form-control " + (this.props.errors.months.length !==0? 'form-error' : '')}
                id="months"
                name="months"
                type="text"
                pattern="[0-9]*"
                inputmode="numeric"
                placeholder="0"
                value={this.props.employment_history.months}
                onChange={this.props.handleChange}
              />
            </div>
            <div className="errors">
              {this.props.errors.months.length !== 0 &&
              <span className="error">{this.props.errors.months}</span>}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
import React from "react";
import axios from "axios";
import { apiUrl, apiSecret } from "../apisettings";
import { minAddressDuration, steps } from "../constants";
import { validation } from "../validation";

export default class Address extends React.Component {
	constructor(props) {
		super(props);
		this._showAddressFields = this._showAddressFields.bind(this);
		this._resetState = this._resetState.bind(this);
		this.state = {
			manualActive: false,
			postcodeResults: [],
			postcode: null,
			postcodeChanged: false,
			selectedAddress: undefined
		};
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.address_history !== this.props.address_history) {
			this.setState({
				postcodeResults: [],
			});
		}
	}

	_showAddressFields = () => {
		const currentState = this.state.manualActive;
		this.setState({ manualActive: !currentState });
	};

	_postcodeResults = () => {
		axios
			.get(
				apiUrl + "postcode/find/" + this.props.address_history.postcode,
				{
					headers: {
						"X-Fuse-Apikey": apiSecret,
					},
				}
			)
			.then((response) => {
				this.setState(
					{
						postcodeResults: response.data.results,
						selectedAddress: response.data.results[0].id
					}
				);
			});
	};

	_resetState = () => {
		this.setState({ postcodeChanged: true });
	};

	render() {
		if (this.props.currentStep !== steps.ADDRESS) {
			return null;
		}
		let error = "";
		if (
			Boolean(this.props.address_history.postcode) &&
			this.props.address_history.postcode.length > 0
		) {
			error = validation.postcode(this.props.address_history.postcode);
		}
		var isEnabled =
			this.state.postcodeChanged &&
			error.length < 1 &&
			Boolean(this.props.address_history.postcode);

		const showUpdateAddress =
			this.state.postcodeResults.length > 0 &&
			Boolean(this.state.selectedAddress) &&
			this.state.selectedAddress !== this.props.address_history.selectedAddress;

		return (
			<React.Fragment>
				<h2 className="subheading">Where do you live?</h2>
				{this.props.duration > 0 && (
					<React.Fragment>
						<div
							className="subtext"
							style={{
								border: "1px solid #D8DEE5",
								borderRadius: "5px",
								margin: "20px",
								padding: "20px",
							}}
						>
							<p className="subtext">
								<strong>Address History</strong>
							</p>
							{this.props.history_array.map((v, i) => {
								return (
									<p key={i}>
										<small>{this.props.addressString(v)} <span style={{color: '#006EF9', cursor: "pointer"}} onClick={() => this.props.editOldAddress(i)}>Edit</span></small>
									</p>
								);
							})}
						</div>
					</React.Fragment>
				)}
				{this.props.duration > 0 &&
					this.props.duration < minAddressDuration && (
						<p className="subtext" style={{ color: "red" }}>
							We require at least three years' address history, please add a previous address.
						</p>
					)
				}
				<div className="formContentWrapper">
					<div className="form-group half-input">
						<label htmlFor="postcode">Postcode Lookup</label>
						<div className="inputWrapper">
							<input
								className="form-control"
								id="postcode"
								name="postcode"
								type="text"
								placeholder="Postcode"
								value={this.props.address_history.postcode}
								onChange={(e) => {
									this.props.handleChange(e);
									this._resetState(e);
								}}
							/>
							<button
								disabled={!isEnabled}
								className="postcodeSearch"
								onClick={(e) => {
									//this._showPostcodeSelect(e);
									this._postcodeResults(e);
								}}
							>
								<img src="/search.svg" alt="" />
							</button>
						</div>
						<div className="errors">
							{error.length > 0 && (
								<span className="error">{error}</span>
							)}
						</div>
						<div className="information">
							<button
								className="info"
								onClick={this._showAddressFields}
							>
								Or enter manually
							</button>
						</div>
					</div>
					<div
						className={
							"form-group full-input hidden-fields " +
							(this.state.postcodeResults.length > 0
								? "active"
								: "")
						}
					>
						<label htmlFor="selectedAddress">Address Results</label>
						<select
							ref={this.addressSelect}
							className="form-control"
							id="selectedAddress"
							name="selectedAddress"
							placeholder="Choose Correct Address"
							value={this.state.selectedAddress}
							onChange={(e) => {
								this.setState({selectedAddress: e.target.value})
							}}
						>
							{this.state.postcodeResults.map((v, i) => {
								return (
									<option key={v.id} value={v.id}>
										{v.labels[1]}
									</option>
								);
							})}
						</select>
					</div>
					<div className={"form-group full-input hidden-fields " + (showUpdateAddress ? 'active' : '')}>
                        <button 
                            className="component-button navactive next"
							onClick={() => {
								this.props.handleChange({target: {
									name: "selectedAddress",
									value: this.state.selectedAddress
								}});
								this.props.selectAddress();
							}}
                        >
                            Use Address
                        </button>
                    </div>
					<div
						className={
							"hidden-fields " +
							(this.state.manualActive
								? "active"
								: "")
						}
					>
						<div className="form-group half-input">
							<label htmlFor="building">
								Building Number/Name
							</label>
							<input
								className="form-control"
								id="building"
								name="building"
								type="text"
								placeholder="Building Number/Name"
								value={this.props.address_history.building}
								onChange={this.props.handleChange}
							/>
						</div>
						<div className="form-group full-input">
							<label htmlFor="line1">Line 1</label>
							<input
								className={
									"form-control " +
									(this.props.errors.line1.length !== 0
										? "form-error"
										: "")
								}
								id="line1"
								name="line1"
								type="text"
								placeholder="Line 1"
								value={this.props.address_history.line1}
								onChange={this.props.handleChange}
							/>
							<div className="errors">
								{this.props.errors.line1.length !== 0 && (
									<span className="error">
										{this.props.errors.line1}
									</span>
								)}
							</div>
						</div>
						<div className="form-group full-input">
							<label htmlFor="street">Line 2</label>
							<input
								className={"form-control"}
								id="line2"
								name="line2"
								type="text"
								placeholder="line2"
								value={this.props.address_history.line2}
								onChange={this.props.handleChange}
							/>
						</div>
						<div className="form-group full-input">
							<label htmlFor="street">Town</label>
							<input
								className={
									"form-control " +
									(this.props.errors.town.length !== 0
										? "form-error"
										: "")
								}
								id="town"
								name="town"
								type="text"
								placeholder="Town"
								value={this.props.address_history.town}
								onChange={this.props.handleChange}
							/>
							<div className="errors">
								{this.props.errors.town.length !== 0 && (
									<span className="error">
										{this.props.errors.town}
									</span>
								)}
							</div>
						</div>
						<div className="form-group full-input">
							<label htmlFor="street">County</label>
							<input
								className={
									"form-control " +
									(this.props.errors.county.length !== 0
										? "form-error"
										: "")
								}
								id="county"
								name="county"
								type="text"
								placeholder="County"
								value={this.props.address_history.county}
								onChange={this.props.handleChange}
							/>
							<div className="errors">
								{this.props.errors.county.length !== 0 && (
									<span className="error">
										{this.props.errors.county}
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
